import {createSharedComposable} from '@vueuse/core'

const _useDashboard = () => {
  const route = useRoute()
  const router = useRouter()
  const isRightSidebarOpen = ref(false)
  const isNotificationsSlideoverOpen = ref(false)

  defineShortcuts({
    'g-h': () => router.push('/'),
    'g-i': () => router.push('/inbox'),
    'g-u': () => router.push('/users'),
    'g-s': () => router.push('/settings'),
    'g-m': () => isRightSidebarOpen.value = true,
    n: () => isNotificationsSlideoverOpen.value = true
  })

  watch(() => route.fullPath, () => {
    isRightSidebarOpen.value = false
    isNotificationsSlideoverOpen.value = false
  })

  return {
    isRightSidebarOpen,
    isNotificationsSlideoverOpen
  }
}

export const useDashboard = createSharedComposable(_useDashboard)
