<script lang="ts" setup>
import {useNavigationStore} from '~/stores/navigation'
import {storeToRefs} from 'pinia'
import Glossy from '~/components/atoms/Button/Glossy.vue'

const {background} = storeToRefs(useNavigationStore())
const {isRightSidebarOpen} = useDashboard()

useHead({
  bodyAttrs: {
    class: 'h-full',
  },
  htmlAttrs: {
    class: 'h-full',
  },
})

const items = [
  [{
    label: 'Profile',
    avatar: {
      src: 'https://avatars.githubusercontent.com/u/739984?v=4'
    }
  }], [{
    label: 'Edit',
    icon: 'i-heroicons-pencil-square-20-solid',
    shortcuts: ['E'],
    click: () => {
      console.log('Edit')
    }
  }, {
    label: 'Duplicate',
    icon: 'i-heroicons-document-duplicate-20-solid',
    shortcuts: ['D'],
    disabled: true
  }], [{
    label: 'Archive',
    icon: 'i-heroicons-archive-box-20-solid'
  }, {
    label: 'Move',
    icon: 'i-heroicons-arrow-right-circle-20-solid'
  }], [{
    label: 'Delete',
    icon: 'i-heroicons-trash-20-solid',
    shortcuts: ['⌘', 'D']
  }]
]

const navigationLinks = [
  {
    label: 'Menu',
    links: [
      {label: 'Home', path: '/home'},
      {label: 'Games', path: '/games'},
    ]
  },
  {
    label: 'Social',
    links: [
      {label: 'Messages', path: '/messages'},
      {label: 'Party', path: '/party'},
    ]
  },
  {
    label: 'General',
    links: [
      {label: 'Settings', path: '/settings'},
    ]
  }
]
</script>

<template>
  <div
    :style="{ 'background-image': background }"
    class="flex h-screen select-none "
    style="background-color: #9ac3ff"
  >
    <div
      class="flex w-full flex-col bg-black/80"
    >
      <nav class="flex h-16 shrink-0 gap-8 p-4 text-white">
        <img class="h-10" src="~/assets/img/logos/gameverse-white.svg">
        <div class="flex w-full items-center justify-between">
          <Glossy />
          <div class="flex items-center gap-4">
            <div class="flex flex-col">
              <div class="flex items-center gap-3">
                Party
                <UChip color="green" size="sm" />
              </div>

              <span class="text-xs text-zinc-400">
                Click to open</span>
            </div>
            <UAvatarGroup :max="3" size="sm">
              <UAvatar
                alt="benjamincanac"
                src="https://avatars.githubusercontent.com/u/739984?v=4"
              />
              <UAvatar
                alt="Atinux"
                src="https://avatars.githubusercontent.com/u/904724?v=4"
              />
              <UAvatar
                alt="smarroufin"
                src="https://avatars.githubusercontent.com/u/7547335?v=4"
              />
            </UAvatarGroup>

            <UButton color="gray" icon="i-heroicons-bell" size="lg" variant="link" />
            <UButton
              color="gray"
              icon="i-heroicons-chat-bubble-oval-left-ellipsis"
              size="lg"
              variant="link"
              @click="isRightSidebarOpen = !isRightSidebarOpen"
            />
          </div>
        </div>
      </nav>

      <div class="flex flex-1 overflow-hidden">
        <div class="relative flex h-full w-52 shrink-0 flex-col gap-10 overflow-hidden p-4 text-white">
          <div v-for="group in navigationLinks" :key="group.label" class="ml-10 flex flex-col gap-4">
            <div class="text-xs font-semibold text-gray-400">
              {{ group.label }}
            </div>
            <div class="flex flex-col gap-4">
              <ULink
                v-for="link in group.links"
                :key="link.label"
                :to="{ path: link.path }"
                active-class="font-semibold text-white before:content-[''] before:w-1 before:h-6 before:rounded-full before:bg-blue-300/80 before:absolute before:left-0 before:block"
                inactive-class="text-base"
              >
                {{ link.label }}
              </ULink>
            </div>
          </div>
          <div class="mt-auto flex w-full flex-col items-center justify-between">
            <div class="relative size-32">
              <div class="absolute inset-0 flex items-center justify-center">
                <UAvatar size="3xl" />
              </div>
              <div class="relative size-full">
                <svg class="absolute inset-0 size-full" viewBox="0 0 36 36">
                  <path
                    :stroke-dasharray="`${3}, 100`"
                    class="text-indigo-500"
                    d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>

            <UDropdown
              :items="items"
              :popper="{ strategy: 'absolute', placement: 'top' }"
              :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
              class="w-full"
            >
              <div class="flex w-full flex-col items-center text-xl font-semibold">
                Pseudo
                <span class="text-xs text-zinc-400">
                  title</span>
              </div>

              <template #account="{ item }">
                <div class="text-left">
                  <p>
                    Signed in as
                  </p>
                  <p class="truncate font-medium text-gray-900 dark:text-white">
                    {{ item.label }}
                  </p>
                </div>
              </template>

              <template #item="{ item }">
                <span class="truncate">{{ item.label }}</span>

                <UIcon :name="item.icon" class="ms-auto size-4 shrink-0 text-gray-400 dark:text-gray-500" />
              </template>
            </UDropdown>
          </div>
        </div>

        <div class="flex-1 overflow-auto rounded-t-3xl bg-black/30 p-10">
          <slot />
        </div>
      </div>
    </div>

    <div
      v-show="isRightSidebarOpen"
      class="flex h-full w-64 shrink-0 flex-col gap-2 divide-y divide-gray-700 overflow-hidden bg-black/80 text-white"
    >
      <div class="max-h-1/2 flex flex-col gap-4 p-4">
        <div class="flex flex-col items-start justify-start gap-0.5">
          <div class="flex items-center justify-start text-xl font-bold leading-none text-white">
            Party
          </div>
          <div class="flex items-center justify-center gap-2">
            <div class="text-xs font-normal leading-3 text-zinc-400">
              1 people in the party
            </div>
            <div class="size-0.5 rounded-full bg-zinc-400" />
            <div class="text-xs font-normal leading-3 text-zinc-400">
              No game
            </div>
          </div>
        </div>
        <div class="flex justify-between gap-1">
          <UButtonGroup class="w-2/3">
            <UInput color="white" placeholder="party" size="sm" variant="outline" />
            <UButton color="gray" icon="i-heroicons-clipboard" size="sm" />
            <UButton color="gray" icon="i-heroicons-clipboard" size="sm" />
          </UButtonGroup>
          <div class="flex justify-end">
            <UButton color="gray" icon="i-heroicons-cog-6-tooth" size="sm" variant="link" />
          </div>
        </div>
        <div class="flex justify-between">
          <div class="flex items-center gap-2">
            <UAvatar />
            <div class="inline-flex flex-col gap-0.5">
              <div class="text-base font-semibold leading-none text-white">
                User
              </div>
              <div class="text-xs text-zinc-400">
                Status
              </div>
            </div>
          </div>

          <UDropdown
            :items="items"
            :popper="{ placement: 'bottom-start' }"
            :ui="{ item: { disabled: 'cursor-text select-text' } }"
          >
            <UButton color="gray" icon="i-heroicons-ellipsis-vertical" size="sm" variant="link" />
          </UDropdown>
        </div>
        <div class="flex justify-between">
          <div class="flex items-center gap-2">
            <UAvatar />
            <div class="inline-flex flex-col gap-0.5">
              <div class="text-base font-semibold leading-none text-white">
                User
              </div>
              <div class="text-xs text-zinc-400">
                Status
              </div>
            </div>
          </div>

          <UDropdown
            :items="items"
            :popper="{ placement: 'bottom-start' }"
            :ui="{ item: { disabled: 'cursor-text select-text' } }"
          >
            <UButton color="gray" icon="i-heroicons-ellipsis-vertical" size="sm" variant="link" />
          </UDropdown>
        </div>

        <div class="flex justify-between">
          <div class="flex items-center gap-2">
            <UAvatar />
            <div class="inline-flex flex-col gap-0.5">
              <div class="text-base font-semibold leading-none text-white">
                User
              </div>
              <div class="text-xs text-zinc-400">
                Status
              </div>
            </div>
          </div>

          <UDropdown
            :items="items"
            :popper="{ placement: 'bottom-start' }"
            :ui="{ item: { disabled: 'cursor-text select-text' } }"
          >
            <UButton color="gray" icon="i-heroicons-ellipsis-vertical" size="sm" variant="link" />
          </UDropdown>
        </div>
        <div class="flex justify-between">
          <div class="flex items-center gap-2">
            <UAvatar />
            <div class="inline-flex flex-col gap-0.5">
              <div class="text-base font-semibold leading-none text-white">
                User
              </div>
              <div class="text-xs text-zinc-400">
                Status
              </div>
            </div>
          </div>

          <UDropdown
            :items="items"
            :popper="{ placement: 'bottom-start' }"
            :ui="{ item: { disabled: 'cursor-text select-text' } }"
          >
            <UButton color="gray" icon="i-heroicons-ellipsis-vertical" size="sm" variant="link" />
          </UDropdown>
        </div>
        <div class="flex justify-between">
          <div class="flex grow items-center gap-2">
            <UAvatar />
            <div class="inline-flex flex-col gap-0.5">
              <UProgress :value="70" size="sm" />
              <div class="text-base font-semibold leading-none text-white">
                User
              </div>
              <div class="text-xs text-zinc-400">
                Disconnected... (53s)
              </div>
            </div>
          </div>

          <UDropdown
            :items="items"
            :popper="{ placement: 'bottom-start' }"
            :ui="{ item: { disabled: 'cursor-text select-text' } }"
          >
            <UButton color="gray" icon="i-heroicons-ellipsis-vertical" size="sm" variant="link" />
          </UDropdown>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center justify-between gap-2 overflow-hidden">
            <div class="flex items-center justify-center rounded-full bg-gray-900/30 p-2">
              <UIcon class="size-5" name="i-heroicons-globe-alt" />
            </div>

            <div class="flex min-w-0 flex-col gap-0.5 overflow-hidden">
              <div class="text-base font-semibold leading-none text-white">
                Searching...
              </div>
              <div class="truncate text-xs text-zinc-400">
                We are looking for the perfect mates for you
              </div>
            </div>
          </div>

          <UButton color="gray" icon="i-heroicons-no-symbol" variant="link" />
        </div>
        <div class="flex justify-between gap-2">
          <div class="grow">
            <UButton block color="gray" icon="i-heroicons-user-plus" label="Find a new mate" size="lg" />
          </div>
          <UButton
            color="gray"
            icon="i-heroicons-pencil-square"
            size="lg"
            square
            variant="solid"
          />
        </div>
        <UButtonGroup>
          <UInput class="w-full" placeholder="Search" size="lg" />
          <UButton color="gray" label="Join" size="lg" />
        </UButtonGroup>
      </div>
      <div class="flex flex-col gap-4 p-4">
        <div class="flex items-center justify-between">
          <div class="inline-flex font-bold text-white">
            Messages
            <UButton color="gray" label="View all" size="xs" variant="link" />
          </div>
          <UButton color="gray" icon="i-heroicons-magnifying-glass" size="lg" variant="link" />
        </div>
        <div class="flex flex-col gap-4">
          <div class="flex items-center justify-between gap-2 rounded-3xl rounded-r-lg bg-white/5 pr-3">
            <div class="flex items-center gap-2">
              <UAvatar size="lg" />
              <div class="flex flex-col items-start gap-0.5">
                <div class="text-base font-semibold leading-none text-white">
                  User
                </div>
                <div class="max-w-full text-ellipsis text-xs text-zinc-400">
                  Status
                </div>
              </div>
            </div>

            <UIcon class="size-5 text-zinc-400" name="i-heroicons-user-group" />
          </div>
          <div class="flex items-center justify-between gap-2 overflow-hidden rounded-3xl rounded-r-lg bg-white/5 pr-3">
            <div class="flex items-center gap-2 overflow-hidden">
              <UAvatar size="lg" />
              <div class="flex min-w-0 flex-1 flex-col items-start gap-0.5">
                <div class="text-base font-semibold leading-none text-white">
                  User
                </div>
                <div class="max-w-full truncate text-xs text-zinc-400">
                  statusddddddddddddddddddddddd
                </div>
              </div>
            </div>

            <UIcon class="size-5 shrink-0 text-zinc-400" name="i-heroicons-user-group" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
